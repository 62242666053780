import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    crops: [{id: 1, name: "Olivar", n: 12, p205: 4, k2o: 8, mgo: 0.5}],
    nitrofoska: [{id: 1, name: "Nitrofoska Super", n: 20, p205: 5, k2o: 10, mgo: 3, cao: 0, so3: 12.5},
                {id: 2, name: "Nitrofoska Perfect", n: 15, p205: 5, k2o: 20, mgo: 2, cao: 0, so3: 20},
                {id: 3, name: "Nitrofoska Especial", n: 12, p205: 12, k2o: 17, mgo: 2, cao: 0, so3: 20},
                {id: 4, name: "Entec Nitrofoska 21", n: 21, p205: 8, k2o: 11, mgo: 2, cao: 0, so3: 10},
                {id: 5, name: "Entec Nitrofoska 14", n: 14, p205: 7, k2o: 17, mgo: 3, cao: 0, so3: 22.5},
                {id: 6, name: "Entec 20 8 10", n: 20, p205: 8, k2o: 10, mgo: 2, cao: 0, so3: 7.5},
                {id: 7, name: "Entec 20 10 10", n: 20, p205: 10, k2o: 10, mgo: 0, cao: 0, so3: 7.5},
                {id: 8, name: "Entec 26", n: 26, p205: 0, k2o: 0, mgo: 0, cao: 0, so3: 32.5}],
    spending: [{id: 1, name: "Tradicional no mecanizado", PodaDesvareto: 188, MantenimientoSuelo: 264, PlagasEnfermedades: 137, Riego: 0, Recoleccion: 367, Fertilizacion: 30},
            {id: 2, name: "Tradicional mecanizado secano", PodaDesvareto: 247, MantenimientoSuelo: 381, PlagasEnfermedades: 156, Riego: 0, Recoleccion: 595, Fertilizacion: 30},
            {id: 3, name: "Tradicional mecanizado riego", PodaDesvareto: 247, MantenimientoSuelo: 381, PlagasEnfermedades: 156, Riego: 434, Recoleccion: 910, Fertilizacion: 90},
            {id: 4, name: "Olivar intensivo secano", PodaDesvareto: 251, MantenimientoSuelo: 372, PlagasEnfermedades: 190, Riego: 0, Recoleccion: 615, Fertilizacion: 30},
            {id: 5, name: "Olivar intensivo riego", PodaDesvareto: 251, MantenimientoSuelo: 372, PlagasEnfermedades: 190, Riego: 472, Recoleccion: 920, Fertilizacion: 150},
            {id: 6, name: "Superintensivo", PodaDesvareto: 430, MantenimientoSuelo: 223, PlagasEnfermedades: 282, Riego: 511, Recoleccion: 810, Fertilizacion: 150}],
  },
  getters: {
    getSpendingById: (state) => (idType) => {
      return state.spending.find(type => type.id == idType)
    },
    getNitrofoskaById: (state) => (idNitro) => {
      return state.nitrofoska.find(nitro => nitro.id == idNitro)
    },
    getCropById: (state) => (idCrop) => {
      return state.crops.find(crop => crop.id == idCrop)
    }
  },
  computed: {
  },
  mutations: {
    modifyPodaSpending (state, data) {
      state.spending[data.id-1]["PodaDesvareto"] = data.value;
    },
    modifyMantenimientoSpending (state, data) {
      state.spending[data.id-1]["MantenimientoSuelo"] = data.value;
    },
    modifyPlagasSpending (state, data) {
      state.spending[data.id-1]["PlagasEnfermedades"] = data.value;
    },
    modifyRiegoSpending (state, data) {
      state.spending[data.id-1]["Riego"] = data.value;
    },
    modifyRecoleccionSpending (state, data) {
      state.spending[data.id-1]["Recoleccion"] = data.value;
    },
    modifyFertilizacionSpending (state, data) {
      state.spending[data.id-1]["Fertilizacion"] = data.value;
    },
  },
  actions: {
  },
  modules: {
  }
})
