<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <div class="d-flex align-center">
      <v-img
        alt="Eurochem Logo"
        class="shrink mr-2"
        contain
        :src="require('../assets/logo-eurochem.svg')"
        transition="scale-transition"
        width="150"
        @click="web()"
        style="cursor: pointer"
      />
    </div>

    <v-spacer></v-spacer>
    <h2>Cálculo de rentabilidad de abonado Eurochem</h2>
    <v-spacer></v-spacer>

    <v-btn
      @click="print()"
      text
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    print(){
      window.print();
    },
    web(){
      window.open('https://www.eurochemiberia.com/', '_blank')
    }
  }
}
</script>
<style>
@media print {
  body { color-adjust: exact; -webkit-print-color-adjust: exact; }
  button { display: none !important; }

  .v-input__append-inner { display: none !important; }

  div.saltopagina{
    display:block;
    page-break-after:always;
  }
}
@media only screen and (max-width: 768px) {
  h2 { display: none !important; }
}
</style>