<template>
  <div class="inicio">
    <v-form ref="form">
      <v-container>
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="name"
              :rules="inputRules"
              label="Nombre"
              type="text"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            />
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="phone"
              label="Teléfono"
              type="tel"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            />
          </v-flex>
          <v-flex xs5>
            <v-text-field
              v-model="city"
              label="Localidad"
              type="text"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            />
          </v-flex>
        </v-layout>
        <v-expansion-panels elevation="2" style="margin-top: 5%;" class="noPrint">
          <v-expansion-panel>
            <v-expansion-panel-header>Gastos por hectárea</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout>
                <v-flex xs12>
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="textCentered"></th>
                          <th class="textCentered">Poda y desvareto</th>
                          <th class="textCentered">Mantenimiento del suelo</th>
                          <th class="textCentered">Plagas y enfermedades</th>
                          <th class="textCentered">Riego</th>
                          <th class="textCentered">Recolección</th>
                          <th class="textCentered">Fertilización foliar y fertirrigación</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="index in 6" :key="index">
                          <td>{{ $store.getters.getSpendingById(index)["name"] }}</td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['PodaDesvareto']"
                            @change="$store.commit('modifyPodaSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes();}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['MantenimientoSuelo']"
                            @change="$store.commit('modifyMantenimientoSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes()}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['PlagasEnfermedades']"
                            @change="$store.commit('modifyPlagasSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes()}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['Riego']"
                            @change="$store.commit('modifyRiegoSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes()}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['Recoleccion']"
                            @change="$store.commit('modifyRecoleccionSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes()}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                          <td><v-text-field
                            :value="$store.getters.getSpendingById(index)['Fertilizacion']"
                            @change="$store.commit('modifyFertilizacionSpending',{id: index, value: $event}); if(type!=null){getDataTotalTypes()}"
                            class="textCentered"
                            type="number"
                            step="0.5"
                            min="0"
                            autocomplete="off"
                          ></v-text-field></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-layout style="margin-top: 2%;">
          <v-flex xs3>
            <v-select
              v-model="crop"
              @change="getDataCrops"
              :items="itemsCrops"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-details
              label="Cultivo"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="ha"
              label="ha"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            />
          </v-flex>
          <v-flex xs4>
            <v-select
              v-model="type"
              @change="getDataTotalTypes"
              :items="itemsTypes"
              item-text="name"
              item-value="id"
              hide-no-data
              hide-details
              label="Tipo de explotación"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="totalTypes"
              @change="calculateResults()"
              label="Gastos sin abono granulado"
              type="number"
              autocomplete="off"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-text-field
              v-model="productiontha"
              @change="calculateHA(); calculateProduction()"
              label="Prod t/ha"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="productionkgp"
              label="Prod kg/planta"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs1>
            <v-text-field
              v-model="frame1"
              @change="calculateHA(); calculateProduction()"
              label="Marco"
              class="textCentered"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <span class="centerVertically">X</span>
          <v-flex xs1>
            <v-text-field
              v-model="frame2"
              @change="calculateHA(); calculateProduction()"
              class="textCentered"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 style="margin-left: 1%;">
            <v-text-field
              v-model="frameTotal"
              label="Planta / ha"
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <span class="centerVertically" style="margin-right: 5%;">Necesidades por tonelada</span>
          <v-flex xs2>
            <v-text-field
              v-model="needsCropN"
              label="Nitrógeno (N)"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="needsCropP2O5"
              label="Fósforo (P2O5)"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="needsCropK2O"
              label="Potasio (K2O)"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="needsCropMgO"
              label="Magnesio (MgO)"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card elevation="2" style="margin-top: 2%;">
          <v-card-subtitle style="font-weight: bold">Comparativa entre Nitrofoska y abono convencional</v-card-subtitle>
          <v-layout>
            <v-flex xs12>
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Abonado suelo</th>
                      <th class="text-left">Nitrógeno (N)</th>
                      <th class="text-left">Fósforo (P2O5)</th>
                      <th class="text-left">Potasio (K2O)</th>
                      <th class="text-left">Magnesio (MgO)</th>
                      <th class="text-left">kg/ha</th>
                      <th class="text-left">€/kg</th>
                      <th class="text-left">€/ha</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <v-select
                          v-model="nitrofoska"
                          @change="getDataNitrofoska(); calculateKgHa()"
                          :items="itemsNitrofoska"
                          item-text="name"
                          item-value="id"
                          hide-no-data
                          hide-details
                          style="font-weight: bold"
                          prepend-inner-icon="mdi-pencil-circle"
                        ></v-select>
                      </td>
                      <td style="font-weight: bold">{{ needsN }}</td>
                      <td style="font-weight: bold">{{ needsP2O5 }}</td>
                      <td style="font-weight: bold">{{ needsK2O }}</td>
                      <td style="font-weight: bold">{{ needsMgO }}</td>
                      <td style="font-weight: bold">{{ KgHa }}</td>
                      <td style="font-weight: bold"><v-text-field
                        v-model="EurKg"
                        @change="calculateEurHa()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td style="font-weight: bold">{{ EurHa }}</td>
                    </tr>
                    <tr>
                      <td class="textLeft" style="font-weight: bold">UF</td>
                      <td style="font-weight: bold">{{ UFn }}</td>
                      <td style="font-weight: bold">{{ UFp2o5 }}</td>
                      <td style="font-weight: bold">{{ UFk2o }}</td>
                      <td style="font-weight: bold">{{ UFmgo }}</td>
                    </tr>
                    <tr>
                      <td class="textLeft">Convencional</td>
                      <td><v-text-field
                        v-model="convencionalN"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        v-model="convencionalP2O5"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        v-model="convencionalK2O"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        v-model="convencionalMgO"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        v-model="convencionalKgHa"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        v-model="convencionalEurKg"
                        @change="calculateConvencional()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td>{{ convencionalEurHa }}</td>
                    </tr>
                    <tr>
                      <td class="textLeft">UF</td>
                      <td>{{ convencionalUFn }}</td>
                      <td>{{ convencionalUFp2o5 }}</td>
                      <td>{{ convencionalUFk2o }}</td>
                      <td>{{ convencionalUFmgo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
          <v-card-title>Diferencia €/ha: {{ difference }}</v-card-title>
        </v-card>
        <v-layout style="margin-top: 2%;">
          <span class="centerVertically" style="margin-right: 5%;">Aceituna €/ha</span>
          <v-flex xs3>
            <v-text-field
              v-model="priceAove"
              @change="calculatePriceAove()"
              label="Precio Aceite Virgen €/kg"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs3 style="margin-left: 2%">
            <v-text-field
              v-model="yields"
              @change="calculatePriceAove()"
              label="Rendimiento"
              type="number"
              step="0.5"
              min="0"
              autocomplete="off"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-text-field>
          </v-flex>
          <v-flex xs3 style="margin-left: 2%">
            <v-text-field
              v-model="totalAove"
              label="Precio aceituna €/ha"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <span class="centerVertically" style="margin-right: 5%; font-weight: bold">{{ needsName }} se paga con</span>
          <v-flex xs2>
            <v-text-field
              v-model="payKgHa"
              label="kg/ha"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="payKgHaP"
              label="% kg/ha"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs2 style="margin-left: 2%">
            <v-text-field
              v-model="payKgPl"
              label="kg/planta"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="payKgPlP"
              label="% kg/planta"
              type="number"
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <div class="saltopagina"></div>
        <v-card elevation="2" style="margin-top: 3%;">
          <v-card-title><br><br></v-card-title>
          <v-layout>
            <v-flex xs12>
              <v-simple-table>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="textCentered">Con {{ needsName }}</th>
                      <th class="textCentered">Convencional</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Gastos</td>
                      <td>{{ spendingNitrofoska }}</td>
                      <td>{{ spendingConvencional }}</td>
                    </tr>
                    <tr>
                      <td>kg / ha para cubrir gastos</td>
                      <td>{{ KgHaSpendingNitrofoska }}</td>
                      <td>{{ KgHaSpendingConvencional }}</td>
                    </tr>
                    <tr>
                      <td>% incremento</td>
                      <td><v-text-field
                        v-model="incrementNitrofoska"
                        @click="calculateResults()"
                        @change="calculateResults()"
                        class="textCentered"
                        type="number"
                        step="0.5"
                        min="0"
                        autocomplete="off"
                        prepend-inner-icon="mdi-pencil-circle"
                      ></v-text-field></td>
                      <td><v-text-field
                        style="visibility: hidden"
                        readonly
                      ></v-text-field></td>
                    </tr>
                    <tr>
                      <td>kg / ha</td>
                      <td>{{ KgHaNitrofoska }}</td>
                      <td>{{ KgHaConvencional }}</td>
                    </tr>
                    <tr>
                      <td>kg / planta</td>
                      <td>{{ KgPlNitrofoska }}</td>
                      <td>{{ KgPlConvencional }}</td>
                    </tr>
                    <tr>
                      <td style="font-weight: bold">Beneficio € / ha</td>
                      <td style="font-weight: bold">{{ benefitNitrofoska }}</td>
                      <td style="font-weight: bold">{{ benefitConvencional }}</td>
                    </tr>
                    <tr>
                      <td>Coste aceituna € / kg</td>
                      <td>{{ oliveNitrofoska }}</td>
                      <td>{{ oliveConvencional }}</td>
                    </tr>
                    <tr>
                      <td>Coste aceite € / kg</td>
                      <td>{{ aoveNitrofoska }}</td>
                      <td>{{ aoveConvencional }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">Gastos fijos de almazara: {{ cooperativeExpenses }} €/kg</td>
                    </tr>
                  </tbody>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              outlined
              label="Observaciones"
              elevation="2" style="margin-top: 3%; resize: both;"
              prepend-inner-icon="mdi-pencil-circle"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import store from '../store/index.js'
import * as basics from '../components/basics.js'
export default {
  name: 'Inicio',
  components: {

  },
  data: () => ({
    inputRules: [
      v => !!v || 'El campo es obligatorio',
    ],
    name: "",
    phone: "",
    city: "",
    itemsCrops: store.state.crops,
    crop: null,
    needsCropN: "",
    needsCropP2O5: "",
    needsCropK2O: "",
    needsCropMgO: "",
    itemsTypes: store.state.spending,
    type: null,
    totalTypes: 0,
    ha: "",
    productiontha: "",
    productionkgp: "",
    frame1: "",
    frame2: "",
    frameTotal: "",
    difference: 0,
    itemsNitrofoska: store.state.nitrofoska,
    nitrofoska: null,
    needsN: "",
    needsP2O5: "",
    needsK2O: "",
    needsMgO: "",
    needsName: "",
    UFn: "",
    UFp2o5: "",
    UFk2o: "",
    UFmgo: "",
    KgHa: "",
    EurKg: null,
    EurHa: 0,
    convencionalN: 20,
    convencionalP2O5: 5,
    convencionalK2O: 10,
    convencionalMgO: 0,
    convencionalKgHa: 500,
    convencionalEurKg: 0.32,
    convencionalEurHa: 0,
    convencionalUFn: "",
    convencionalUFp2o5: "",
    convencionalUFk2o: "",
    convencionalUFmgo: "",
    priceAove: "",
    yields: "",
    totalAove: "",
    payKgHa: "",
    payKgHaP: "",
    payKgPl: "",
    payKgPlP: "",
    spendingNitrofoska: "",
    spendingConvencional: "",
    KgHaSpendingNitrofoska: "",
    KgHaSpendingConvencional: "",
    KgHaNitrofoska: "",
    KgHaConvencional: "",
    incrementNitrofoska: 8,
    incrementConvencional: 0,
    KgPlNitrofoska: "",
    KgPlConvencional: "",
    benefitNitrofoska: "",
    benefitConvencional: "",
    oliveNitrofoska: "",
    oliveConvencional: "",
    aoveNitrofoska: "",
    aoveConvencional: "",
    cooperativeExpenses: 0.05
  }),
  created() {
    this.calculateConvencional();
  },
  methods: {
    getDataCrops(){
      const data = store.getters.getCropById(this.crop);
      this.needsCropN = data["n"];
      this.needsCropP2O5 = data["p205"];
      this.needsCropK2O = data["k2o"];
      this.needsCropMgO = data["mgo"];
    },
    getDataNitrofoska(){
      const data = store.getters.getNitrofoskaById(this.nitrofoska);
      this.needsN = data["n"];
      this.needsP2O5 = data["p205"];
      this.needsK2O = data["k2o"];
      this.needsMgO = data["mgo"];
      this.needsName = data["name"];
    },
    getDataTotalTypes(){
      const data = store.getters.getSpendingById(this.type);
      this.totalTypes = parseFloat(data["PodaDesvareto"]) + parseFloat(data["MantenimientoSuelo"]) + parseFloat(data["PlagasEnfermedades"]) + parseFloat(data["Riego"]) + parseFloat(data["Recoleccion"]) + parseFloat(data["Fertilizacion"]);
    },
    calculateHA(){
      if(this.frame1!="" && this.frame2!="" && this.productiontha!=""){
        this.frameTotal = basics.round(10000/(this.frame1 * this.frame2),0);
        this.calculatePayNitrofoska();
      }
    },
    calculateProduction(){
      if(this.frame1>0 && this.frame2>0 && this.frame1!="" && this.frame2!="" && this.productiontha!=""){
        this.productionkgp = basics.round((this.productiontha*1000)/this.frameTotal,2);
        this.calculatePayNitrofoska();
      }
    },
    calculateKgHa(){
      if(this.type!=null && this.productiontha!="")
        this.KgHa = basics.round((((this.needsCropN * this.productiontha) * 100) / this.needsN),2);
        this.calculateUFN(); this.calculateUFP2O5(); this.calculateUFK2O(); this.calculateUFMgO();
    },
    calculateEurHa(){
      if(this.KgHa!="" && this.EurKg!=null)
        this.EurHa = basics.round((this.KgHa * this.EurKg),2);
        this.calculateDifference();
    },
    calculateUFN(){
      this.UFn = basics.round(((this.needsN * this.KgHa)/100),2);
    },
    calculateUFP2O5(){
      this.UFp2o5 = basics.round(((this.needsP2O5 * this.KgHa)/100),2);
    },
    calculateUFK2O(){
      this.UFk2o = basics.round(((this.needsK2O * this.KgHa)/100),2);
    },
    calculateUFMgO(){
      this.UFmgo = basics.round(((this.needsMgO * this.KgHa)/100),2);
    },
    calculateConvencional(){
      this.convencionalEurHa = basics.round((this.convencionalKgHa * this.convencionalEurKg),2);
      this.convencionalUFn = basics.round(((this.convencionalN * this.convencionalKgHa) / 100),2);
      this.convencionalUFp2o5 = basics.round(((this.convencionalP2O5 * this.convencionalKgHa) / 100),2);
      this.convencionalUFk2o = basics.round(((this.convencionalK2O * this.convencionalKgHa) / 100),2);
      this.convencionalUFmgo = basics.round(((this.convencionalMgO * this.convencionalKgHa) / 100),2);
      this.calculateDifference();
    },
    calculateDifference(){
      if(this.EurHa!="" && this.convencionalEurHa!=""){
        this.difference = basics.round((this.EurHa - this.convencionalEurHa),2);
        this.calculatePayNitrofoska();
      }
    },
    calculatePriceAove(){
      if(this.priceAove!="" && this.yields!=""){
        this.totalAove = basics.round((this.priceAove / (1 / (this.yields / 100))),2);
        this.calculatePayNitrofoska();
      }
    },
    calculatePayNitrofoska(){
      if(this.difference>0 && this.totalAove!="" && this.productiontha!="" && this.frameTotal!=""){
        this.payKgHa = basics.round((this.difference / this.totalAove),2);
        this.payKgHaP = basics.round(((100 * this.payKgHa) / (this.productiontha * 1000)),2);
        this.payKgPl = basics.round((this.payKgHa / this.frameTotal),2);
        this.payKgPlP = basics.round(((100 * this.payKgPl) / (this.productionkgp)),2);
        this.calculateResults();
      }
    },
    calculateResults(){
      if(this.totalTypes!=0 && this.convencionalEurHa!=0 && this.EurHa!=0 && this.totalAove!="" && this.productiontha!="" && this.yields!=""){
        this.spendingConvencional = basics.round((parseFloat(this.totalTypes) + parseFloat(this.convencionalEurHa)),2);
        this.spendingNitrofoska = basics.round((parseFloat(this.totalTypes) + parseFloat(this.EurHa)),2);
        this.KgHaSpendingConvencional = basics.round((this.spendingConvencional / this.totalAove),2);
        this.KgHaSpendingNitrofoska = basics.round((this.spendingNitrofoska / this.totalAove),2);
        this.KgHaConvencional = basics.round(((this.productiontha * 1000 * this.incrementConvencional / 100) + (this.productiontha * 1000)),2);
        this.KgHaNitrofoska = basics.round((this.KgHaConvencional + ((this.KgHaConvencional * this.incrementNitrofoska) / 100)),2);
        this.KgPlConvencional = basics.round((this.KgHaConvencional / this.frameTotal),2);
        this.KgPlNitrofoska = basics.round((this.KgHaNitrofoska / this.frameTotal),2);
        this.benefitConvencional = basics.round(((this.KgHaConvencional - this.KgHaSpendingConvencional)*this.totalAove),2);
        this.benefitNitrofoska = basics.round(((this.KgHaNitrofoska - this.KgHaSpendingNitrofoska)*this.totalAove),2);
        this.oliveConvencional = basics.round((this.spendingConvencional / this.KgHaConvencional),2);
        this.oliveNitrofoska = basics.round((this.spendingNitrofoska / this.KgHaNitrofoska),2);
        this.aoveConvencional = basics.round(((this.spendingConvencional + this.KgHaConvencional * this.cooperativeExpenses) / (this.KgHaConvencional * this.yields / 100)),2);
        this.aoveNitrofoska = basics.round(((this.spendingNitrofoska + this.KgHaNitrofoska * this.cooperativeExpenses) / (this.KgHaNitrofoska * this.yields / 100)),2);
      }
    }
  }
}
</script>
<style scoped>
.inicio { text-align: center; }
.centerVertically { display: flex; align-items: center; }
.textCentered { text-align: center !important }
.textCentered >>> input { text-align: center !important }
.textLeft { text-align: left !important }
/* .editable >>> .v-input__slot:before  {
  border-color: #006192 !important;
} */
@media print {
  .noPrint { display: none !important; }
  .v-text-field >>> .v-input__prepend-inner { display: none !important;}
}
</style>